export default [
    {
        path: "/admin/chat/basic",
        name: "chat-basic",
        component: () => import("@/views/chat/basic.vue"),
    },
    {
        path: "/admin/chat/basic/:sid",
        name: "chat-basic-sid",
        component: () => import("@/views/chat/basic.vue"),
    },
]